import { trackingLiterals } from '../../setup/tracking/trackingLiterals'
import settings from '../../settings'
import sha256 from 'crypto-js/sha256'
import { matchGAClient } from '../../util/format'
import TagManager from 'react-gtm-module'
import { EMAIL, LENGTH_EMPTY, PAGE_ASSET_NAMES } from '../../util/constants'
import { isNativeAndroid, isNativeiOS } from '../../util/native/nativeUtils'
import { DateHelpers } from 'f1-utils'

const datesLanguage = 'en'
const campainData = 'campain_data'
const ecommerceData = 'e_comerse_item'
const campaignParams = {
  campaign: 'utm_campaign',
  source: 'utm_source',
  medium: 'utm_medium',
  term: 'utm_term',
  content: 'utm_content',
}

export const initializeTagManager = (gtmId) => {
  if (!gtmId) return

  const tagManagerInicialArgs = {
    gtmId: gtmId,
  }

  TagManager.initialize(tagManagerInicialArgs)
}

export const getAge = (birthdate) => {
  if (birthdate) {
    const dateOfBirth = new Date(birthdate).getFullYear()
    const now = new Date().getFullYear()
    return now - dateOfBirth
  }
}

export const getEmailConsentCheck = (communicationChannels) => {
  if (Array.isArray(communicationChannels)) {
    return communicationChannels.some((check) => {
      return check.key === EMAIL
    })
  }
  return false
}

export const getUser = (currentSubscriberData) => {
  return {
    ga_client: getGaClient(),
    user_agent: navigator.userAgent,
    subscribed_newsletter: getEmailConsentCheck(
      currentSubscriberData?.communicationChannels
    )
      ? trackingLiterals.ABS.yes
      : trackingLiterals.ABS.no,
    logged: currentSubscriberData
      ? trackingLiterals.ABS.yes
      : trackingLiterals.ABS.no,
    country: settings.country,
    gender: currentSubscriberData?.gender || trackingLiterals.ABS.notAvailable,
    age: getAge(currentSubscriberData?.birthdate),
    last_loggin_date: '',
    id_crm: currentSubscriberData?.id,
    postal_code:
      currentSubscriberData?.address?.zip || trackingLiterals.ABS.notAvailable,
    city:
      currentSubscriberData?.address?.city || trackingLiterals.ABS.notAvailable,
    email_hass_sha256: sha256(currentSubscriberData?.email).toString(),
  }
}

export const getGaClient = () => {
  return document.cookie.replace(matchGAClient.match, matchGAClient.patern)
}

const getPlatFormAssetName = () => {
  if (isNativeAndroid()) {
    return PAGE_ASSET_NAMES.android
  }

  if (isNativeiOS()) {
    return PAGE_ASSET_NAMES.ios
  }

  return PAGE_ASSET_NAMES.web
}

export const getPageFixParams = () => {
  const nowDate = DateHelpers.getNowTrackingDate(datesLanguage)

  return {
    date: nowDate.date,
    country: settings.country,
    referer: document.referrer,
    time: nowDate.time,
    weekday: nowDate.day,
    asset: getPlatFormAssetName(),
  }
}

export const setCampain = (searchParams) => {
  if (!searchParams) {
    return
  }
  const campain = {
    campaign: searchParams.get(campaignParams.campaign),
    traffic_source: searchParams.get(campaignParams.source),
    medium: searchParams.get(campaignParams.medium),
    term: searchParams.get(campaignParams.term),
    content: searchParams.get(campaignParams.content),
  }
  sessionStorage.setItem(campainData, JSON.stringify(campain))
}

export const getCampaign = () => {
  return JSON.parse(sessionStorage.getItem(campainData))
}

export const setEComerseItem = (item) => {
  sessionStorage.setItem(ecommerceData, JSON.stringify(item))
}

export const getEComerseItem = () => {
  try {
    const data = JSON.parse(sessionStorage.getItem(ecommerceData))
    return data
  } catch {
    return { items: [] }
  }
}

export const trackEvent = (dataLayer) => {
  TagManager.dataLayer(dataLayer)
}

export const buildCommerceItem = ({ vehicle, index }) => {
  if (!vehicle) return {}
  const pricingFirstIndex = 0
  const centsOfCurrency = 100

  const vehicleCountry = vehicle?.location?.address?.country
  const vehicleCity = vehicle?.location?.address?.city
  const vehiclePrice = (
    vehicle.pricing[pricingFirstIndex].value / centsOfCurrency
  ).toString()

  return {
    item_name: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
    item_id: vehicle.id,
    price: vehiclePrice,
    item_brand: vehicle.make,
    item_category: vehicle.model,
    item_category2: vehicle.class,
    item_category3: `${trackingLiterals.ecommerce.CategoryPrefix}${vehicleCountry}_${vehicleCity}`,
    item_category4: trackingLiterals.ecommerce.NewSuscription,
    item_category5: trackingLiterals.ecommerce.pickUp,
    item_variant: trackingLiterals.ecommerce.NewSuscription,
    item_list_name: trackingLiterals.ecommerce.ListNameFamiliares,
    item_list_id: `${trackingLiterals.ecommerce.ListIndexPrefix}${
      index || LENGTH_EMPTY
    }`,
    index: index || LENGTH_EMPTY,
    currency: settings.currencyConfig.abbreviation,
    quantity: trackingLiterals.numbers.one,
  }
}
