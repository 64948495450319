import { useCallback } from 'react'
import { useParams } from 'react-router'
import { getDefaultCountryCode } from '../util/countryHelper'

export default function useBuildUrl() {
  const { lang, countryCode } = useParams()
  const navigateCountryCode = countryCode || getDefaultCountryCode()

  const buildUrl = useCallback(
    (internalPath) => `/${navigateCountryCode}/${lang}${internalPath}`,
    [lang, navigateCountryCode]
  )

  return buildUrl
}
